import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import SlidingGallery from "./components/SlidingGallery";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import Mint from "./components/Mint"; // Import the Mint page

function App() {
  return (
    <Router>
      <div
        className="min-h-screen flex flex-col"
        style={{
          background: "linear-gradient(180deg, #a4c4fc, #ffd1dc, #fff6b7, #e5d3ff)", // Gradient: Blue -> Pink -> Yellow -> Purple
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <Navbar />
        <div className="flex-grow">
          <Routes>
            {/* Define routes for HeroSection, Gallery, and Mint */}
            <Route
              path="/"
              element={
                <>
                  <HeroSection />
                  <SlidingGallery />
                </>
              }
            />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/mint" element={<Mint />} /> {/* Added Mint route */}
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
