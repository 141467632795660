import React from "react";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <div className="text-center py-20 px-8 md:px-20">
      <h1 className="text-5xl font-bold text-primaryText">
        Welcome to Doodle Dawgs
      </h1>
      <p className="text-lg text-primaryText mt-4">
        Unleash that Dawg in you with <span className="font-semibold">Doodle Dawgs</span>—a fun art collection brought to you by <span className="font-semibold">Crafty Canines</span> from the Sei Network. These hand-drawn, personality-packed NFTs embody creativity and individuality. While originally from Sei, this exciting mint is happening on the <span className="font-semibold">Abstract Network</span>. These vibrant canines are all about celebrating art and having fun.
      </p>

      <div className="flex flex-col items-center gap-4 mt-6">
        <Link to="/gallery">
          <button className="px-6 py-2 bg-pastelGreen text-primaryText font-semibold rounded-lg hover:bg-pastelYellow">
            Explore the Collection
          </button>
        </Link>
        <a 
          href="https://magiceden.us/collections/abstract/0xcc2a505c21e7bf25503dcb936a7af42a4958af6e" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <button className="px-6 py-2 bg-pastelBlue text-primaryText font-semibold rounded-lg hover:bg-pastelYellow">
            Buy On Magic Eden
          </button>
        </a>
      </div>
    </div>
  );
};

export default HeroSection;
