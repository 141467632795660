const Footer = () => {
  return (
    <footer className="text-primaryText text-center py-4">
      <p className="text-sm">
        &copy; 2024-2025 Doodle Dawgs. All rights reserved. <br />
        Powered by{" "}
        <a
          href="https://www.craftycanines.xyz/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-secondaryText hover:underline"
        >
          Crafty Canines
        </a>
      </p>
    </footer>
  );
};

export default Footer;
