import React from "react";

const Mint = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: "linear-gradient(180deg, #a4c4fc, #ffd1dc, #fff6b7, #e5d3ff)",
        padding: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          maxWidth: "90%", // Restrict width to 90% of viewport or parent container
          width: "100%",   // Full width of the container
          overflow: "hidden", // Ensure no overflow
        }}
      >
        <iframe
          id="iframe-widget"
          src="https://0xcc2a505c21e7bf25503dcb936a7af42a4958af6e_2741.nfts2.me/?widget=classic&hideBanner=true"
          style={{
            height: "800px",      // Fixed height as specified
            width: "100%",        // Full width within container
            maxWidth: "100%",     // Prevent overflow
            transform: "scale(0.95)",  // Slightly shrink the iframe
            transformOrigin: "top center", // Center the scaling
            border: "none",       // Remove border
          }}
          title="Mint Doodle Dawg"
        ></iframe>
      </div>
    </div>
  );
};

export default Mint;
